<template>
  <div>
    <Loader v-if="loading"></Loader>
    <CRow v-else>
      <CCol lg="12">
        <AbstractTable :fields="fields" :items="advices" :actions="actions" title="Consejos"
          :labelItemPerPage="'Consejos por página:'"
          :addBtn="{ enable: $store.state.isAdmin, text: 'Añadir un nuevo consejo', event: 'addElement' }" @addElement="addAdvice"
          @showDeleteModal="handleModal" :noResults="'No se han encontrado consejos'" />
        <DeleteModal :modalContent="modalContent" @delete="deleteAdviceData(adviceId)" :showModal="deleteModal" @closeModal="closeModal" />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import AbstractTable from "@/components/Tables/AbstractTable";
import DeleteModal from "@/components/Modals/DeleteModal.vue";
import Loader from "@/components/Loader.vue";
import { getAdvicesData, deleteAdvice } from '@/services/advices'
import { generateField } from '@/utils/utils'

export default {
  name: "adviceTable",
  components: { AbstractTable, DeleteModal, Loader },
  data() {
    return {
      loading: true,
      deleteModal: false,
      advices: [],
      adviceId: 0,
      adviceName: '',
      fields: [
        generateField("advice_title", "Nombre del consejo", {styleData: { textAlign: 'center'}}),
        generateField("advice_description", "Descripción del consejo"),
        generateField("actions", 'Acciones', { show: this.$store.state.isAdmin, sorter: false, filter: false })
      ],
      actions: [
        {
          key: 'delete',
          label: 'Eliminar',
          isAdmin: !this.$store.state.isAdmin,
          event: 'showDeleteModal',
          classButton: 'btn btn-delete btn-sm btn-pill'
        },
      ],
      modalContent: {
            title: 'Eliminar consejo',
            textBody: '',
            buttons: [
                {
                  textButton: 'Cerrar',
                  color: 'secondary',
                  event: 'closeModal'
                },
                {
                  textButton: 'Aceptar',
                  color: 'danger',
                  event: 'delete'
                },
            ]
      }
    }
  },
  created() {
    this.receiveData();
  },
  methods: {
    receiveData() {
      this.getAdvices();
    },
    /**
     * Función en la que se obtienen las consejos y que también
     * llama el metodo receiveData para cargarlos.
     * 
     * @returns {object[]} Las consejos obtenidos.
     */
    getAdvices() {
      getAdvicesData()
        .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() }; this.loading = false;})
        .then((result) => {
        this.advices = result
        this.advices.forEach((advice) => {
          advice.id = advice.id;
          advice.advice_title = advice.title;
          advice.advice_description = advice.description;
        })
        this.loading = false;
      });
    },
    /**
     * Este es el evento que se ejecuta al hacer click en el boton de eliminar de la tabla acciones
     * Eso hace que se abra el modal
     * 
     * @param {object} advice - El consejo sobre el que se ha llamado al evento
     */
    deleteButton(advice) {
      this.advice = advice;
      this.deleteModal = true;
    },
    /**
     * Este metodo, que se ejecuta al hacer click en el nombre del consejo, nos llevará a la página para ver la información del consejo
     * 
     * @param {number} id - El Id del consejo sobre el que se llama el evento
     */
    adviceInfo(id) {
      this.$router.push("/advice/info/" + id);
    },
    /**
     * Este metodo, que se ejecuta al hacer click en el botón Añadir un nuevo consejo y redirige a la vista con el formulario
     * */
    addAdvice() {
      this.$router.push(`/advice/create/`);
    },
    /**
     * Método para controlar lo que se muestra en el Modal de Eliminar consejo.
     * */
    handleModal(item) {
      this.adviceId = item.id;
      this.modalContent.textBody = `¿Está seguro de que desea eliminar el consejo: <strong>"${item.advice_title}</strong>"? Esta acción no se puede deshacer.`,
      this.deleteModal = true;
    },
    /**
     * Este metodo eliminará el consejo, mandando por la URL el id
     * 
     * @param {number} id - El id del consejo a eliminar
     */
    deleteAdviceData(id) {
      deleteAdvice(id).then(() => {
        this.getAdvices()
      })
      this.deleteModal = false;
    },
    /**
     * Función para cerrar el Modal.
     */
    closeModal(){
        this.deleteModal = false;
    }
  },
};
</script>